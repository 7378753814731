import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "project-overview"
    }}>{`Project overview`}</h1>
    <p>{`This is the main entry point for developers to lookup information about projects. For project backlog management, we use jira (`}<a parentName="p" {...{
        "href": "https://hybrit.atlassian.net"
      }}>{`https://hybrit.atlassian.net`}</a>{`). You will have access to jira by default with a hybrit.org account, so you can use google to login. Official documents like contracts, SLA's and statement of work are stored on google drive.`}</p>
    <h1 {...{
      "id": "working-on-projects"
    }}>{`Working on projects`}</h1>
    <p>{`When working on projects, it's good to agree on a common way of working for all members and definitions of
when work on features can start and when it's completed.`}</p>
    <p>{`Normally these agreements are defined by the project team, but when nothing has been set the following applies.`}</p>
    <h2 {...{
      "id": "way-of-working"
    }}>{`Way of working`}</h2>
    <p>{`The definitions stated below take into account an agile way of working where changes are frequently demonstrated, approved by stakeholders and released to production.`}</p>
    <p>{`The use of feature flags (toggles) is advised, as it enables the breakup of features into smaller chunks of work without blocking production releases.`}</p>
    <h2 {...{
      "id": "definition-of-done"
    }}>{`Definition of Done`}</h2>
    <p>{`A story or bug is done when all the following requirements are met:`}</p>
    <ol>
      <li parentName="ol">{`Quality:`}<ol parentName="li">
          <li parentName="ol">{`Pull request is reviewed by at least 1 peer`}</li>
          <li parentName="ol">{`Automated test coverage is at least `}<strong parentName="li">{`70%`}</strong>{` (on new code)`}</li>
          <li parentName="ol">{`Sonar scan passes, using the agreed quality gate`}</li>
        </ol></li>
      <li parentName="ol">{`Deployment:`}<ol parentName="li">
          <li parentName="ol">{`The feature or bug fix is deployed on staging`}</li>
          <li parentName="ol">{`The feature is disabled using a feature flag (if required)`}</li>
        </ol></li>
      <li parentName="ol">{`Documentation:`}<ol parentName="li">
          <li parentName="ol">{`Usage documentation is updated according to the changes`}</li>
          <li parentName="ol">{`Architecture documentation is updated according to the changes`}</li>
          <li parentName="ol">{`API is documented using OAS (Swagger)`}</li>
          <li parentName="ol">{`User guide is updated according to the changes`}</li>
          <li parentName="ol">{`Written documentation changes are reviewed by at least 1 peer`}</li>
        </ol></li>
    </ol>
    <h2 {...{
      "id": "definition-of-ready"
    }}>{`Definition of Ready`}</h2>
    <p>{`To enable the team to meet all requirements in the definition of done, stories and bugs are only ready to start work when the following requirements are met:`}</p>
    <h4 {...{
      "id": "story"
    }}>{`Story`}</h4>
    <ol>
      <li parentName="ol">{`The goal is defined in a SMART way.`}</li>
      <li parentName="ol">{`The scope is clearly defined`}<ol parentName="li">
          <li parentName="ol">{`Acceptance criteria`}</li>
          <li parentName="ol">{`UI Design (if applicable, could be a link)`}</li>
        </ol></li>
      <li parentName="ol">{`Does the feature require a feature toggle?`}</li>
    </ol>
    <h4 {...{
      "id": "bug"
    }}>{`Bug`}</h4>
    <p>{`A bug needs either:`}</p>
    <ol>
      <li parentName="ol">{`Link to sentry error/issue`}</li>
      <li parentName="ol">{`Reproduction path, containing:`}<ol parentName="li">
          <li parentName="ol">{`environment`}</li>
          <li parentName="ol">{`username`}</li>
          <li parentName="ol">{`steps to reproduce:`}<ul parentName="li">
              <li parentName="ul">{`API: request (Method, URL, body)`}</li>
              <li parentName="ul">{`UI: steps to get to the point of the error or unexpected outcome`}</li>
            </ul></li>
          <li parentName="ol">{`expected result`}</li>
          <li parentName="ol">{`actual result:`}<ul parentName="li">
              <li parentName="ul">{`UI: print screen`}</li>
              <li parentName="ul">{`API: response (Status, body)`}</li>
              <li parentName="ul">{`Stack trace (if available)`}</li>
            </ul></li>
        </ol></li>
    </ol>
    <blockquote>
      <p parentName="blockquote">{`Stories and bugs should have a parent issue (epic).`}</p>
    </blockquote>
    <h2 {...{
      "id": "collaborating-as-a-team"
    }}>{`Collaborating as a team`}</h2>
    <ul>
      <li parentName="ul">{`PR reviews`}<ul parentName="li">
          <li parentName="ul">{`Pull-requests are reviewed at least at the start of each day.`}</li>
        </ul></li>
      <li parentName="ul">{`Tasks (getting work done together)`}<ul parentName="li">
          <li parentName="ul">{`Stories are divided into subtasks`}</li>
          <li parentName="ul">{`Subtasks describe what to do where (i.e. add CRUD endpoints in service A) and should enable completion within 1 day`}</li>
          <li parentName="ul">{`WIP limit should be 1.`}</li>
          <li parentName="ul">{`We focus on finishing a tasks before starting a new task.`}</li>
          <li parentName="ul">{`Feature branches are linked to a subtask (by lifecycle and naming)`}</li>
        </ul></li>
      <li parentName="ul">{`Software delivery (CI/CD)`}<ul parentName="li">
          <li parentName="ul">{`Changes are delivered to a distributed environment as soon as possible (after merge)`}</li>
          <li parentName="ul">{`When a build fails, fixing it has the highest priority`}</li>
          <li parentName="ul">{`...`}</li>
        </ul></li>
      <li parentName="ul">{`Customer feedback`}</li>
      <li parentName="ul">{`...`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      